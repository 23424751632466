@media screen and (max-width: 450px) {

  .logo-metamask {
    bottom: 6%;
  }

  .nav-tab-container {
    display: none !important;
  }

  .btn-account {
    width: 29% !important;
    box-sizing: content-box !important;
  }

  .bodega-frame {
    width: fit-content !important;
  }

  .warestore-bkrg {
    width: 100% !important;
  }

  .wrstr-clss {
    padding-left: 0px !important;
  }

  .article-new {
    transform: rotateZ(146deg) !important;
    position: absolute !important;
    left: 250px !important;
    top: 454px !important;
  }

  .intro-btn {
    left: 0;
  }
}

@media screen and (max-height: 404px) {
  .row>* {
    width: 30% !important;
  }

}

@media screen and (min-height: 390px) {
  .row>* {
    width: 30% !important;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ant-result-subtitle {
  color: white;
}

.ant-result-title {
  color: white;
}

.ant-image-img {
  width: 25% !important;
}

.ant-modal-close {
  display: none !important;
}

.ant-modal-footer {
  display: none !important;
}


.article-new {
  transform: rotateZ(146deg);
  position: absolute;
  left: 550px;
  top: 454px;
  z-index: 250;
  width: 2.5%;
}

/*/b/*/

.btn-account {
  position: fixed !important;
  z-index: 150 !important;
  text-align: center !important;
  background: #004743 !important;
  margin: 0 !important;
  width: 15% !important;
  left: 70% !important;
  top: 7px !important;
}

.btn-login {
  z-index: 450;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20%;
}


.back-50-res {
  background: linear-gradient(to top right, #30262e 0%, #267bc0 100%) !important;
}


.backcard {
  background: linear-gradient(to top right, #9febcc 0%, #d77f7f 100%) !important;
  box-shadow: rgb(138 199 146 / 80%) 0px 20px 56px;
}

.backcity {
  background-image: url('../src/assets/about/postervid.jpg') !important;

}

.background-black {
  background: linear-gradient(to top right, #2c2c2c 0%, #0f0f1d 100%) !important;
  width: 100% !important;
  color: burlywood;
}

.b-inv {
  background: transparent !important;
}

.bodega-frame {
  overflow-x: hidden !important;
}

button {
  background: #004743 !important;
  border-color: #075551 !important;
}

/*/c/*/

.card-left {
  position: absolute;
  transform: rotate(20deg);
}

.card-right {
  position: absolute;
  transform: rotate(-10deg);
}

.card-midle {
  position: absolute;
}

.color-set-brow {
  color: burlywood !important;
}

.col {
  flex: 1 0;
  justify-content: center;
  display: inline-grid;
}

.c-pointer {
  cursor: pointer !important;
}

/*/D/*/

.d-n {
  display: none !important;
}

.d-froot {
  display: flow-root !important;
  width: 100%;
}

/*/E/*/

.exist-art {
  display: none;
}

/*/F/*/

.frame-space {
  min-height: 100vh;
  max-width: 100%;
  overflow: hidden;
  height: auto;
}

.footer-blck {
  width: 100% !important;
  height: 250px !important;
  background: linear-gradient(to top right, #2c2c2c 0%, #0f0f1d 100%) !important;
  color: burlywood;
}

.footer-container {
  text-align: center !important;
  box-sizing: inherit;
}

.frames-on-account {
  background: linear-gradient(to top right, #ffffff 0%, #267bc0 100%) !important;
  width: 100%;
  height: 100%;

}

.frame-left {
  background: linear-gradient(to top right, #ffffff 0%, #267bc0 100%) !important;
}

/*/G/*/
.glass-effect {
  position: relative;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  overflow: hidden;
}

.glass-effect:before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.5) 25%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(255, 255, 255, 0.5) 75%,
      rgba(255, 255, 255, 0) 100%);
  transform: rotate(-30deg);
  z-index: 150;
  pointer-events: none;
  animation: glass-shine 2s linear infinite;
}

@keyframes glass-shine {
  0% {
    transform: rotate(-30deg) translateX(-200%);
  }

  100% {
    transform: rotate(-30deg) translateX(200%);
  }
}

/*/I/*/
.img-card {
  max-width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 10px;
  position: relative;
  z-index: 2;
}

.icon-marketplace {
  font-size: 9.2em;
  color: rgb(18 159 129) !important;
}

/*/L/*/

.logo-rug {
  width: 30%;
  bottom: 5%;
  left: 10%;
}

.logo-metamask {
  width: 10%;
  bottom: 7%;
  right: 14%;
}

.logo-class {
  top: 3.5% !important;
}


/*/M/*/
.max-card {
  overflow-y: scroll !important;
  max-height: 480px;
}


/*/N/*/

.neon-text {
  font-size: 0.3rem;
  color: #b81eff;
  /* Color morado */
  text-transform: uppercase;
  letter-spacing: 8px;
  position: relative;
  text-shadow: 0 0 10px #b81eff, 0 0 20px #b81eff, 0 0 30px #b81eff;
  /* Sombra neon */
  animation: blink 3.5s infinite;
  margin-top: 100px;
}

@keyframes blink {
  0% {
    opacity: 0.2;
    /* Inicio con baja opacidad */
  }

  50% {
    opacity: 1;
    /* Opacidad completa a la mitad de la animación */
  }

  100% {
    opacity: 0.2;
    /* Baja opacidad al final */
  }
}

.no-display {
  display: none !important;
}

.no-abc {
  width: 240px;
}

.no-path-d {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 10%;
}

/*/M/*/

.midle-text {

  writing-mode: horizontal-tb;

}

.moto-id {
  width: 10%;
  bottom: 5%;
  left: 10%;
}



/*/O/*/

.on-display {
  display: block !important;
  ;
  position: fixed !important;
  ;
  width: 100% !important;
  ;
  z-index: 99 !important;
  ;
  border-bottom: 1px solid rgb(14, 11, 11);
}

/*/P/*/

.position-col {
  padding-left: 17% !important;
  padding-top: 2.5%;
}

.p-absolute {
  position: absolute !important;
}

/*/S/*/

.size-16 {
  font-size: 1.2em !important;
}

.spacework {
  width: 100%;
  height: 450px;
}

.social-btn{ 
  z-index: 550;
  display: flex;
  justify-content: center;
  margin-top: 20%;
  bottom: 5%;
  left : 45%
}

/*/T/*/

.t-alingh {
  text-align: justify;
  font-size: large;
  padding: 2.5%;
}


/*/V/*/

.video-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 150;
}

video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

/*/W/*/

.w-100 {
  width: 100% !important;
}

.w-50 {
  width: 50% !important;
}


#WEB3_CONNECT_MODAL_ID {
  z-index: 1000;
  position: fixed;

}

.wrstr-clss {
  padding-left: 25%;
}

/*/Z/*/
.z-w10h10 {
  z-index: 5;
  width: 100% !important;
  height: 100% !important;
  position: fixed !important;
}

.z-10 {
  z-index: 250;
  position: absolute;
  margin-left: 15%;
}

.z-15 {
  z-index: 15;
}

.z-150 {
  z-index: 250;
}

/*corto es el dia larga es la hora */
/*ya ahi acaba weww*/

/*/Extra Css/*/

/*/Account and my grid /*/

.menu-container {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 200px;
  background: linear-gradient(to top right, #2c2c2c 0%, #0f0f1d 100%) !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.menu {
  padding: 10px;
}

ul {
  list-style: none !important;
  padding: 0;
  margin: 0;
}

li {
  margin-bottom: 20px;
  list-style: none !important;
  cursor: pointer !important;
}

li:hover {
  color: #267bc0;
  text-shadow: 1px #101a30;
  box-shadow: inset;
}


/*/EffectsByMaszov/*/


.intro-btn {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 350;
  cursor: pointer;
  font-size: 0.3rem;
  color: #71ff1e;
  /* Color morado */
  text-transform: uppercase;
  letter-spacing: 8px;
  position: relative;
  text-shadow: 0 0 10px #1eff8f, 0 0 20px #1eff8f, 0 0 30px #1eff1e;
  /* Sombra neon */
  animation: blink 3.5s infinite;
}

@keyframes blink {
  0% {
    opacity: 0.2;
    /* Inicio con baja opacidad */
  }

  50% {
    opacity: 1;
    /* Opacidad completa a la mitad de la animación */
  }

  100% {
    opacity: 0.2;
    /* Baja opacidad al final */
  }
}